import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SendolaBlackWhiteLogo from "@/assets/Img/sendola-black-white-logo.png";
import SendolaBlackLogo from "@/assets/Img/sendola-black-logo.png";
import SendolaBlackMockup from "@/assets/Img/sendola-black-mockup.png";
import DesktopYacht from "@/assets/Img/desktop-yatch.png";
import ExteriorHome from "@/assets/Img/exterior-home.jpg";
import SkiMountain from "@/assets/Img/ski-mountain.jpg";
import HomeElDorado from "@/assets/Img/home-el-dorado.jpg";
import AssetsBankGrade from "@/assets/Img/assets-bank-grade.png";
import BusinessMan from "@/assets/Img/business-man.jpg";
import GlobalReach from "@/assets/Img/global-reach.png";
import CostEffective from "@/assets/Img/cost-effective.png";
import DedicatedSupport from "@/assets/Img/dedicated-support.png";
import EmailSupport from "@/assets/Img/email-support.png";
import PhoneSupport from "@/assets/Img/phone-support.png";
import TraditionalBankingGraphicMobile from "@/assets/Img/traditional-banking-graphic-mobile.png";
import OctGraphic1 from "@/assets/Img/oct-graphic-1.png";
import OctGraphic1Mobile from "@/assets/Img/oct-graphic-1-mobile.png";
import OctGraphic2 from "@/assets/Img/oct-graphic-2.png";
import UserGoldIcon from "@/assets/Img/user-gold.png";
import Concierge from "@/assets/Img/concierge.png";
import InternationalIcon from "@/assets/Img/international.png";
import TransactionBox3 from "@/assets/Img/transaction-box-3.png";
import TransactionBox2 from "@/assets/Img/transaction-box-2.png";
import TransactionBox3Mobile from "@/assets/Img/transaction-box-3-mobile.png";
import TransactionBox2Mobile from "@/assets/Img/transaction-box-2-mobile.png";
import BankDetails from "@/assets/Img/banks-details.png";
import MapsAmerica from "@/assets/Img/continents-maps-01.png";
import MapsEurope from "@/assets/Img/continents-maps-02.png";
import MapsOceania from "@/assets/Img/continents-maps-03.png";
import MapsAfrica from "@/assets/Img/continents-maps-04.png";
import SendolaBlackVideo from "@/assets/Video/SendolaBlack.mp4";
import QuoteIcon from "@/assets/Icons/quotes.svg?react";
import ExternalLink from "@/assets/Icons/external-link.svg?react";
import { QuestionItem } from "@/components/QuestionItem";
import { Card } from "@/components/Card";
import { Modal } from "@/components/Modal";
import HubspotContactForm from "@/components/HubspotContactForm";
import { Button } from "@/components/Button";
import classNames from "classnames";
import {
  NO_FORMAT_PHONE_NUMBER,
  SENDOLABLACK_SUPPORT_EMAIL,
} from "@/constants/Contact";
import { AuthCard } from "@/components/AuthCard";
import { LoginForm } from "@/components/LoginForm";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const SENDOLA_BLACK_APP_URL = import.meta.env.VITE_SENDOLA_BLACK_APP_URL;
import { CountriesCard } from "@/components/CountriesCard";
import {
  AfricaAvailableCountries,
  AmericaAvailableCountries,
  ComingCountries,
  EuropeAvailableCountries,
  OceaniaAvailableCountries,
} from "@/constants/Countries";

export const SendolaBlackPage = () => {
  const [t] = useTranslation("global");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [isCostModalOpen, setIsCostModalOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [isBannerSticky, setIsBannerSticky] = useState<boolean>(false);

  const bannerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mobileVideoRef = useRef<HTMLVideoElement>(null);

  const openModal = () => {
    setLoadingForm(true);
    setFormSubmitted(false);
    setIsModalOpen(true);
  };
  const openRequestTransferModal = () => {
    const token = cookies.get("jwt_auth");

    if (token) {
      window.location.href = SENDOLA_BLACK_APP_URL;
    } else {
      setIsAuthModalOpen(true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 600) setShowBanner(true);
      else setShowBanner(false);
    };

    if (bannerRef?.current) {
      const windowHeight = window.innerHeight;
      const banner = bannerRef?.current;
      const elementBottom = banner.getBoundingClientRect().bottom;

      const distanceToBottom = windowHeight - elementBottom;

      if (distanceToBottom > 0) {
        setIsBannerSticky(false);
      } else {
        setIsBannerSticky(true);
      }
    }

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (window.innerWidth >= 640) {
            videoRef.current?.play();
          } else {
            mobileVideoRef.current?.play();
          }
        } else {
          try {
            videoRef.current?.pause();
          } catch {
            console.log();
          }
          try {
            mobileVideoRef.current?.pause();
          } catch {
            console.log();
          }
        }
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    if (mobileVideoRef.current) {
      observer.observe(mobileVideoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="relative w-full flex flex-col items-center h-fit gold-radial-gradient px-6 lg:px-10">
          <img
            className="w-60 md:w-80 lg:w-96 h-auto py-10 z-10"
            src={SendolaBlackWhiteLogo}
            alt="Sendola Black's logo"
          />
          <img
            className="w-full object-contain max-w-screen-md  -mt-[10vh] sm:-mt-[17vh] translate-y-[29.6%]"
            src={DesktopYacht}
            alt="Sendola Black's logo"
          />
        </div>

        <div className="bg-sendolab-gray flex justify-center px-6 lg:px-10 pb-20 pt-52 xs:pt-48 md:pt-60">
          <div className="w-full flex flex-col md:flex-row gap-8 rounded-3xl bg-sendolab-brown p-20 max-w-screen-xl">
            <div className="h-full flex items-center w-full md:w-1/2">
              <p className="font-optima text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-sendolab-gray">
                {t("SendolaBlack.Section1.Title")}
              </p>
            </div>
            <div className="flex flex-col justify-center gap-4 w-full md:w-1/2">
              <p
                className="text-sendolab-white font-light inner-b-semibold text-sm md:text-base lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section1.Paragraph1"),
                }}
              />
              <p
                className="text-sendolab-white font-light inner-b-semibold text-sm md:text-base lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section1.Paragraph2"),
                }}
              />
            </div>
          </div>
        </div>

        <div
          id="why-choose-us"
          className="flex flex-col gap-10 w-full py-10 pt-20 px-6 lg:px-10 relative"
        >
          <img
            src={ExteriorHome}
            className="absolute left-0 top-0 w-full h-full object-cover object-top"
          />
          <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-white/20 to-55% to-black/30" />

          <div className="w-full z-10">
            <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl mt-10 text-sendolab-brown font-optima">
              {t("SendolaBlack.Section3.Title")}
            </h1>
            <p className="text-center text-2xl lg:text-3xl 2xl:text-4xl text-sendolab-brown mt-6 font-optima">
              {t("SendolaBlack.Section3.Subtitle")}
            </p>
          </div>

          <div className="w-full inline-flex flex-wrap items-center justify-center gap-8 z-10 mt-20">
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 pb-20 !shadow-turquoise-sm"
            >
              <img className="object-contain w-16 h-16" src={CostEffective} />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card1.Title")}
              </p>
              <p
                className="text-sendolab-gray text-center"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card1.Paragraph1"),
                }}
              />
            </Card>
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 pb-20 !shadow-turquoise-sm"
            >
              <img className="object-contain w-16 h-16" src={GlobalReach} />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card2.Title")}
              </p>
              <p
                className="text-sendolab-gray text-center"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card2.Paragraph1"),
                }}
              />
            </Card>
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 pb-20 !shadow-turquoise-sm"
            >
              <img
                className="object-contain w-16 h-16"
                src={DedicatedSupport}
              />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card3.Title")}
              </p>
              <p
                className="text-sendolab-gray text-center"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card3.Paragraph1"),
                }}
              />
            </Card>
          </div>

          <div className="w-full h-44 flex gap-4 items-center justify-center z-10">
            <QuoteIcon className="text-sendolab-white h-8 w-8 sm:h-16 sm:w-16 md:h-20 md:w-20 flex-shrink-0" />
            <p className="text-white text-base sm:text-lg md:text-xl lg:text-2xl font-optima text-center">
              {t("SendolaBlack.Section4.Title")}
            </p>
            <QuoteIcon className="text-sendolab-white h-8 w-8 sm:h-16 sm:w-16 md:h-20 md:w-20 flex-shrink-0 rotate-180" />
          </div>
        </div>

        <div className="w-full bg-sendolab-brown h-44 flex gap-8 items-center justify-center px-6 lg:px-10">
          <img src={AssetsBankGrade} className="text-sendolab-gray h-16 w-16" />
          <p className="font-semibold text-sendolab-white text-base sm:text-lg md:text-xl lg:text-3xl">
            {t("SendolaBlack.Section2.Title")}
          </p>
        </div>

        <div className="flex justify-center w-full bg-sendolab-turquoise2 px-6 lg:px-10 py-20">
          <div
            id="countries"
            className="flex flex-col gap-10 w-full justify-center items-center max-w-screen-xl"
          >
            <div className="h-full flex flex-col justify-center w-full px-10 gap-4 lg:w-5/6">
              <h1 className="font-optima text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white">
                {t("SendolaBlack.Section11.OurCommitment")}
              </h1>
              <p className="text-center md:text-lg xl:text-xl text-white font-light">
                {t("SendolaBlack.Section11.PartOfExpandedOfferings")}
              </p>
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-6 gap-6 mt-10">
              <CountriesCard
                title={t("SendolaBlack.Section11.Americas")}
                image={
                  <img
                    src={MapsAmerica}
                    className="w-full h-auto max-h-52 object-contain object-center min-w-32"
                  />
                }
                countryList={AmericaAvailableCountries}
                orientation="horizontal"
                className="col-span-1 lg:col-span-3"
              />
              <CountriesCard
                title={t("SendolaBlack.Section11.Europe")}
                image={
                  <img
                    src={MapsEurope}
                    className="w-full h-auto max-h-52 object-contain object-center min-w-32"
                  />
                }
                countryList={EuropeAvailableCountries}
                orientation="horizontal"
                className="col-span-1 lg:col-span-3"
                listWrapperClassName="w-min"
              />
              <CountriesCard
                title={t("SendolaBlack.Section11.Africa")}
                image={
                  <img
                    src={MapsAfrica}
                    className="w-full h-auto max-h-52 object-contain object-center min-w-28 -my-[12%]"
                  />
                }
                countryList={AfricaAvailableCountries}
                orientation="vertical"
                className="col-span-1 lg:col-span-2"
              />
              <CountriesCard
                title={t("SendolaBlack.Section11.Oceania")}
                image={
                  <img
                    src={MapsOceania}
                    className="w-full h-auto max-h-52 object-contain object-center min-w-28 -my-[12%]"
                  />
                }
                countryList={OceaniaAvailableCountries}
                orientation="vertical"
                className="col-span-1 lg:col-span-2"
              />
              <CountriesCard
                title={t("SendolaBlack.Section11.Upcoming2025")}
                countryList={ComingCountries}
                orientation="horizontal"
                className="col-span-1 lg:col-span-2"
                addMoreToComeText
                listWrapperClassName="max-w-none xs:max-w-[260px] sm:max-w-[400px] md:max-w-[560px] lg:max-w-[280px] gap-x-10 xl:gap-x-10"
              />
              <div className="col-span-1 lg:col-span-6 grid grid-cols-1 lg:grid-cols-3">
                <span className="w-full text-xs md:text-sm text-white text-center font-semibold lg:col-start-3 -translate-y-4">
                  {t("Countries.MoreToCome")}
                </span>
              </div>
            </div>

            <p className="text-white text-xl font-light mt-10 lg:w-2/3 text-center">
              {t("SendolaBlack.Section11.ThisExpansion")}
            </p>
          </div>
        </div>

        <div className="flex bg-black xl:py-20">
          <video
            autoPlay
            muted
            loop
            controls
            playsInline
            height={1080}
            width={1920}
            disablePictureInPicture
            disableRemotePlayback
            className="w-full h-fit min-h-full max-w-screen-2xl mx-auto"
            ref={videoRef}
          >
            <source src={SendolaBlackVideo} type="video/mp4" />
          </video>
        </div>

        <div
          id="boost-savings"
          className="w-full flex flex-col justify-center items-center gap-6 bg-black py-16 pt-20 px-6 lg:px-10"
        >
          <div className="w-full">
            <h1 className="text-center w-full text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-gray font-optima mb-2">
              {t("SendolaBlack.Section8.Title")}
            </h1>
            <p className="text-xl lg:text-2xl font-optima text-center w-full text-sendolab-white">
              {t("SendolaBlack.Section8.Subtitle")}
            </p>
          </div>

          <div className="w-fit">
            <div className="hidden w-full sm:flex flex-col items-center md:justify-center md:flex-row">
              <img
                className="w-full max-h-[48rem] object-contain md:-ml-8 max-md:-mt-10 max-md:max-h-[28rem]"
                src={OctGraphic1}
                alt="Cost breakdown table 1"
              />
            </div>

            <div className="flex flex-col sm:hidden">
              <img
                className="w-full max-h-[32rem] object-contain"
                src={TraditionalBankingGraphicMobile}
                alt="Traditional banking table"
              />
              <img
                className="w-full max-h-[32rem] object-contain -mt-4"
                src={OctGraphic1Mobile}
                alt="Cost breakdown table 1"
              />
            </div>

            <p className="w-full text-center text-sm md:text-base lg:text-lg text-[#cca78a] md:mt-4">
              {t("SendolaBlack.Section8.SavingsTitle")}
            </p>
            <img
              className="w-full max-h-[24rem] object-contain px-4"
              src={OctGraphic2}
              alt="Cost breakdown table 2"
            />
          </div>

          <div className="w-full flex flex-col gap-2 md:max-w-[80%]">
            <p className="w-full text-center text-xs md:text-sm text-sendolab-gray font-light">
              {t("SendolaBlack.Section8.Disclaimer1")}
            </p>
            <p className="w-full text-center text-xs md:text-sm text-sendolab-gray font-light">
              {t("SendolaBlack.Section8.Disclaimer2")}
            </p>
          </div>
        </div>

        <div className="w-full relative h-fit">
          <img
            className="w-full object-cover object-center min-h-[24rem] max-h-[48rem]"
            src={BusinessMan}
          />
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full bg-black/35 px-6 lg:px-10">
            <p className="text-3xl sm:text-4xl lg:text-6xl font-optima font-light text-white text-center md:max-w-[60%]">
              {t("SendolaBlack.Section9.Title")}
            </p>
          </div>
        </div>

        <div className="flex justify-center w-full py-8 px-6 md:px-10 bg-sendolab-gray3">
          <div className="flex flex-col sm:flex-row w-full max-w-screen-xl">
            <div className="w-full sm:w-1/2 py-2 md:py-8 px-8 lg:px-12 flex items-center">
              <p className="text-center font-optima text-white text-2xl sm:text-3xl md:texl-4xl xl:text-5xl">
                {t("SendolaBlack.Section12.WithSendola")}
              </p>
            </div>
            <div className="w-full h-[2px] sm:w-[2px] sm:h-full bg-sendolab-gray max-sm:my-4" />
            <div className="w-full sm:w-1/2 flex justify-center flex-col gap-4 py-2 md:py-8 px-8">
              <p className="text-center text-white md:texl-xl lg:text-2xl font-light">
                {t("SendolaBlack.Section12.SendingLimits")}
              </p>
              <p className="text-center text-sendolab-gray text-sm">
                {t("SendolaBlack.Section12.CheckForDetails")}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full bg-sendolab-gray px-6 py-12 flex flex-col items-center">
          <div className="w-full flex flex-col gap-4 items-center justify-center max-w-screen-xl">
            <div className="w-full flex flex-col items-center rounded-3xl p-4">
              <div className="flex justify-center items-center flex-col">
                <img
                  className="hidden md:block w-full h-auto object-contain -mt-12 select-none"
                  src={TransactionBox2}
                />
                <img
                  className="md:hidden max-w-[420px] w-full h-auto object-contain -mt-12 select-none"
                  src={TransactionBox2Mobile}
                />
                <div className="flex flex-col gap-2">
                  <p
                    className="text-center text-sm text-white font-light z-10 mb-1"
                    dangerouslySetInnerHTML={{
                      __html: t("SendolaBlack.Section9.Disclaimer1"),
                    }}
                  />
                  <p
                    className="text-center text-sm text-white font-light z-10 mb-1"
                    dangerouslySetInnerHTML={{
                      __html: t("SendolaBlack.Section9.Disclaimer2"),
                    }}
                  />
                </div>
                <a
                  href="https://s3.us-west-1.amazonaws.com/sendola.io/Black/Payment+Methods+V2.pdf"
                  target="_black"
                  rel="noopener noreferrer"
                  className="text-center text-sm mt-2 text-white underline underline-offset-4 z-10 mb-8 cursor-pointer"
                  dangerouslySetInnerHTML={{
                    __html: t("SendolaBlack.Section9.SeeMore"),
                  }}
                />

                <img
                  className="hidden md:block w-full h-auto object-contain -mt-8 select-none"
                  src={TransactionBox3}
                />
                <img
                  className="md:hidden max-w-[420px] w-full h-auto object-contain -mt-10 select-none"
                  src={TransactionBox3Mobile}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full relative h-fit">
          <img
            className="w-full object-cover object-center min-h-[24rem] max-h-[48rem]"
            src={SkiMountain}
            alt="People skiing"
          />
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full bg-black/35 px-6 lg:px-10">
            <p className="text-xl md:text-3xl lg:text-4xl font-optima text-white text-center max-w-screen-xl">
              {t("SendolaBlack.Section6.Quote")}
            </p>
          </div>
        </div>

        <div className="w-full flex justify-center gold-radial-gradient py-16 md:py-28 px-6 lg:px-10">
          <div className="w-full flex justify-around gap-6 md:gap-10 px-2 md:px-10 lg:max-w-screen-xl">
            <div className="flex flex-col gap-8">
              <p className="font-optima text-sendolab-gold2 text-3xl md:text-4xl lg:text-5xl">
                {t("SendolaBlack.Section6.Title1")}
              </p>
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph1"),
                }}
              />
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph2"),
                }}
              />
              <p className="font-optima text-sendolab-gold2 text-3xl md:text-4xl lg:text-5xl">
                {t("SendolaBlack.Section6.Title2")}
              </p>
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph3"),
                }}
              />
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph4"),
                }}
              />
            </div>
            <img
              className="object-contain w-60 md:w-96 -my-8 max-sm:hidden"
              src={SendolaBlackMockup}
            />
          </div>
        </div>

        <div className="w-full bg-sendolab-white px-6 py-20 flex justify-center">
          <div className="w-full flex flex-col gap-4 items-center justify-center max-w-screen-xl">
            <div className="mb-10">
              <h1
                className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-gray2 font-optima font-semibold"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section7.Title"),
                }}
              />
              <p
                className="text-center text-2xl lg:text-3xl 2xl:text-4xl text-sendolab-gray2 font-light"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section7.Subtitle"),
                }}
              />
            </div>

            <div className="w-full flex flex-col lg:flex-row gap-10 items-center md:px-10">
              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white">
                <img
                  src={UserGoldIcon}
                  className="w-20 h-20 bg-sendolab-turquoise p-4 rounded-3xl flex-shrink-0"
                />
                <p className="font-light text-base lg:text-lg pr-6">
                  {t("SendolaBlack.Section7.Card1.Title")}
                </p>
              </div>

              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white">
                <img
                  src={Concierge}
                  className="w-20 h-20 bg-sendolab-turquoise p-4 rounded-3xl flex-shrink-0"
                />
                <p className="font-light text-base lg:text-lg pr-6">
                  {t("SendolaBlack.Section7.Card2.Title")}
                </p>
              </div>

              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white">
                <img
                  src={InternationalIcon}
                  className="w-20 h-20 bg-sendolab-turquoise p-4 rounded-3xl flex-shrink-0"
                />
                <p className="font-light text-base lg:text-lg pr-6">
                  {t("SendolaBlack.Section7.Card3.Title")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full relative h-fit">
          <img
            className="w-full object-cover object-bottom min-h-[24rem] max-h-[48rem]"
            src={HomeElDorado}
            alt="A House on the beach"
          />
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full bg-black/55 px-6 lg:px-10">
            <p className="text-xl md:text-3xl lg:text-4xl font-optima text-white text-center max-w-screen-xl">
              {t("SendolaBlack.Section9.Subtitle")}
            </p>
          </div>
        </div>

        <div className="w-full bg-sendolab-white flex flex-col gap-4 px-6 py-20 items-center justify-center">
          <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-brown font-optima font-semibold mb-10">
            {t("SendolaBlack.Section5.Title")}
          </h1>

          <p className="font-light text-lg sm:text-xl md:text-2xl lg:text-3xl text-center">
            {t("SendolaBlack.Section5.Subtitle")}
          </p>
          <div className="w-full max-md:flex-col max-md:gap-2 mt-4 items-center flex justify-center gap-6">
            <div className="flex max-xs:flex-col items-center gap-2">
              <a
                href={`mailto:${SENDOLABLACK_SUPPORT_EMAIL}?subject=SendolaBlack Concierge Request`}
                className="flex items-center gap-2 px-4 py-2 transition-all rounded-lg hover:bg-black/20"
              >
                <img className="w-6 h-6" src={EmailSupport} />
                <p className="text-sm md:text-base min-w-max">
                  {t("SendolaBlack.Section5.Method1")}
                </p>
              </a>
              <p className="text-sm text-stone-500">
                {SENDOLABLACK_SUPPORT_EMAIL}
              </p>
            </div>

            <div className="h-[2px] w-20 md:h-20 md:w-[1px] my-2 md:mx-2 bg-sendolab-brown" />

            <div className="flex max-xs:flex-col items-center gap-2">
              <a
                href={`tel:${NO_FORMAT_PHONE_NUMBER}`}
                className="flex items-center gap-2 px-4 py-2 transition-all rounded-lg hover:bg-black/20"
              >
                <img className="w-6 h-6" src={PhoneSupport} />
                <p className="text-sm md:text-base min-w-max">
                  {t("SendolaBlack.Section5.Method2")}
                </p>
              </a>
              <p className="text-sm text-stone-500">+1 (713) 581-5377</p>
            </div>
          </div>
        </div>

        <div
          ref={bannerRef}
          className={classNames(
            "w-full flex items-center gap-4 shadow-t-lg bg-sendolab-turquoise bottom-0 z-20 transition-all delay-100 duration-200 ease-linear p-4 min-h-fit",
            {
              sticky: showBanner,
              "h-32 sm:h-24 max-sm:pb-10 lg:h-20": showBanner || isBannerSticky,
              "block h-0 overflow-hidden": !showBanner,
            }
          )}
        >
          <div
            className={classNames(
              "w-1/2 md:w-8/12 h-full flex items-center transition-all duration-400 pt-1",
              {
                "max-h-0": !showBanner,
                "max-h-24": showBanner,
              }
            )}
          >
            <p className="w-full text-sm md:text-xl lg:text-2xl text-center px-2 md:px-6 text-sendolab-white font-extralight">
              {t("SendolaBlack.Section10.ApplyTitle")}
            </p>
          </div>

          <div className="h-12 w-[1.5px] bg-sendolab-gray mr-4" />

          <div className="w-1/2 md:w-4/12 inline-flex flex-wrap gap-x-4 gap-y-2 items-center justify-center">
            <Button
              onClick={openModal}
              size="sm"
              className="min-w-40 md:min-w-44 !bg-gradient-to-r h-fit !from-[#908257] !to-[#c7b37f] !font-normal !border-0 hover:opacity-90 !px-6"
            >
              {t("SendolaBlack.Header.ApplyButton")}
            </Button>
            <Button
              onClick={openRequestTransferModal}
              size="sm"
              variant="secondary"
              className="min-w-40 md:min-w-44 border-[2px] h-fit border-sendolab-gray text-sendolab-white hover:!bg-black/15 !font-normal rounded-full font-optima px-4 md:px-12 py-2"
            >
              {t("SendolaBlack.Header.RequestTransfer")}
            </Button>
          </div>
        </div>

        <div
          id="faqs"
          className="w-full gold-radial-gradient py-20 px-10 md:px-20 flex justify-center"
        >
          <div className="w-full flex flex-col justify-center items-center gap-6 max-w-screen-xl">
            <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-white lg:max-w-[60%] font-optima mb-4">
              {t("SendolaBlack.Section10.Title")}
            </h1>

            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question1.Question")}
              answer={t("SendolaBlack.Section10.Question1.Answer")}
            />
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question2.Question")}
              answer={t("SendolaBlack.Section10.Question2.Answer")}
            />
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question3.Question")}
            >
              <a
                href="https://s3.us-west-1.amazonaws.com/sendola.io/Black/Payment+Methods+V2.pdf"
                target="_black"
                rel="noopener noreferrer"
                className="flex items-center gap-1 underline underline-offset-4 font-light cursor-pointer"
              >
                {t("SendolaBlack.Section10.Question3.Answer")}
                <ExternalLink className="w-5 h-5" />
              </a>
            </QuestionItem>
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question4.Question")}
              answer={t("SendolaBlack.Section10.Question4.Answer")}
            >
              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <p className="font-semibold text-base">
                  {t("SendolaBlack.Section10.Question4.Americas.Name")}
                </p>
                <li>{t("SendolaBlack.Section10.Question4.Americas.Canada")}</li>
                <li>{t("SendolaBlack.Section10.Question4.Americas.Mexico")}</li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Americas.Guatemala")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Americas.Honduras")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Americas.Colombia")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Americas.Ecuador")}
                </li>
                <li>{t("SendolaBlack.Section10.Question4.Americas.Brazil")}</li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Americas.Argentina")}
                </li>
              </ul>

              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <p className="font-semibold text-base">
                  {t("SendolaBlack.Section10.Question4.Europe.Name")}
                </p>
                <li>
                  {t("SendolaBlack.Section10.Question4.Europe.UnitedKingdom")}
                </li>
                <li>{t("SendolaBlack.Section10.Question4.Europe.Spain")}</li>
                <li>{t("SendolaBlack.Section10.Question4.Europe.Portugal")}</li>
              </ul>

              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <p className="font-semibold text-base">
                  {t("SendolaBlack.Section10.Question4.Africa.Name")}
                </p>
                <li>
                  {t("SendolaBlack.Section10.Question4.Africa.SouthAfrica")}
                </li>
              </ul>

              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <p className="font-semibold text-base">
                  {t("SendolaBlack.Section10.Question4.Oceania.Name")}
                </p>
                <li>
                  {t("SendolaBlack.Section10.Question4.Oceania.Australia")}
                </li>
              </ul>

              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <p className="font-semibold text-base">
                  {t("SendolaBlack.Section10.Question4.Upcoming.Name")}
                </p>
                <li>{t("SendolaBlack.Section10.Question4.Upcoming.India")}</li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Singapore")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Malaysia")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.SouthKorea")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Philippines")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Vietnam")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Switzerland")}
                </li>
                <li>
                  {t("SendolaBlack.Section10.Question4.Upcoming.Uruguay")}
                </li>
                <li>{t("SendolaBlack.Section10.Question4.Upcoming.Peru")}</li>
              </ul>
            </QuestionItem>
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question5.Question")}
              answer={t("SendolaBlack.Section10.Question5.Answer")}
            />
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question6.Question")}
              answer={t("SendolaBlack.Section10.Question6.Answer")}
            />
          </div>
        </div>
      </div>

      <AuthCard show={isAuthModalOpen} close={() => setIsAuthModalOpen(false)}>
        <LoginForm />
      </AuthCard>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full flex flex-col items-center">
          {!formSubmitted && (
            <img
              className="w-48 md:w-80 mb-10"
              src={SendolaBlackLogo}
              alt="Sendola black's logo"
            />
          )}
          {loadingForm && (
            <p className="w-full text-center">{t("Loading")}...</p>
          )}
          <HubspotContactForm
            region="na1"
            portalId="44207752"
            formId="8338283d-2d9c-43c1-ad9d-dedd8617ea5c"
            onReady={() => {
              setLoadingForm(false);
            }}
            onSubmit={() => {
              setFormSubmitted(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        open={isCostModalOpen}
        withClose
        onClose={() => setIsCostModalOpen(false)}
      >
        <div className="w-full flex flex-col items-center">
          <img
            className="w-full object-contain -mt-[10%]"
            src={BankDetails}
            alt="Cost table for other banks 2"
          />
        </div>
      </Modal>
    </>
  );
};
